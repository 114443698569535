
let clock = setTimeout(function clockSet() {
	const today = new Date(); //fetching date

	// date option
	let h = today.getHours();
	let m = today.getMinutes();
	let s = today.getSeconds();
	

	h = checkTime(h);
	m = checkTime(m);
	s = checkTime(s);

	function checkTime(i) {
		if (i < 10) {
			i = '0' + i;
		}
		return i;
	}

	const time =  h + ':' + m + ':' + s; //full time
	document.getElementById('time').textContent = time; //fill time

	clock = setTimeout(clockSet, 1000); //nest setTimeOut 
}, 1000) //repeat every second
