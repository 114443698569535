import '../src/style/main.css';
import '../Public/Assets/Fonts/Manrope-Regular.woff2'
import '../Public/Assets/Fonts/Manrope-Medium.woff2'
import '../Public/Assets/Fonts/Manrope-SemiBold.woff2'
import './time.js';
import './threeportfolio.js';
import { init } from './threeportfolio.js';


window.onload = function () {
    
    document.body.style.display = 'block';
    
}
