import '../src/style/main.css';

import '../Public/Assets/Fonts/Manrope-Regular.woff2';
import '../Public/Assets/Fonts/Manrope-Medium.woff2';
import '../Public/Assets/Fonts/Manrope-SemiBold.woff2';

import '../Public/Assets/Fonts/MontrealBook.ttf';
import '../Public/Assets/Fonts/MontrealMedium.ttf';
import '../Public/Assets/Fonts/MontrealBold.ttf';
import '../Public/Assets/Fonts/MontrealItalic.ttf';

import './time.js';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import faceModel from '../Public/Assets/Models/abstract.glb';
import faceModel2 from '../Public/Assets/Models/abstract2.glb';
import faceModel3 from '../Public/Assets/Models/abstract3.glb';

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.onload = function () {
    document.body.style.display = 'block';
    console.log('loaded son');
};

const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 1000);
camera.position.set(0, 0, 8);

const keyLight = new THREE.DirectionalLight(0xffffff, 1.5);
keyLight.position.set(5, 5, 5);

const fillLight = new THREE.DirectionalLight(0xffffff, 0.75);
fillLight.position.set(-5, 5, 5);

const backLight = new THREE.DirectionalLight(0xffffff, 1);
backLight.position.set(0, 0, -5);

const ambientLight = new THREE.AmbientLight(0xffffff, 0.3);

const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('#bg-three'),
    alpha: true
});
renderer.setPixelRatio(window.devicePixelRatio);
renderer.setClearColor(0xffffff, 0);
renderer.setSize(sizes.width, sizes.height);
renderer.shadowMap.enabled = true;

const scene = new THREE.Scene();
scene.add(camera);
scene.add(keyLight);
scene.add(fillLight);
scene.add(backLight);
scene.add(ambientLight);

const loader = new GLTFLoader();

let example, example2, example3; 

function loadModels() {
    loader.load(faceModel, function (gltf) {
        example = gltf.scene;
        updateModelScale(example);
        example.rotation.z = THREE.MathUtils.degToRad(24);
        example.position.set(1, 0, 0);
        scene.add(example);
    });

    loader.load(faceModel2, function (gltf) {
        example2 = gltf.scene;
        updateModelScale(example2);
        example2.rotation.z = THREE.MathUtils.degToRad(-98);
        example2.position.set(-1, 1, 2);
        scene.add(example2);
    });

    loader.load(faceModel3, function (gltf) {
        example3 = gltf.scene;
        updateModelScale(example3);
        example3.rotation.z = THREE.MathUtils.degToRad(30);
        example3.rotation.x = THREE.MathUtils.degToRad(240);
        example3.position.set(-2, 0, 0);
        scene.add(example3);
    });
}

function updateModelScale(model) {
    const scaleFactor = window.innerWidth / sizes.width;
    model.scale.set(scaleFactor, scaleFactor, scaleFactor);
}

loadModels();

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);

    if (example) updateModelScale(example);
    if (example2) updateModelScale(example2);
    if (example3) updateModelScale(example3);
});

const targetRotation1 = new THREE.Euler(0, 0, 0);
const currentRotation1 = new THREE.Euler(0, 0, 0);
const targetRotation2 = new THREE.Euler(THREE.MathUtils.degToRad(41), 0, 0);
const currentRotation2 = new THREE.Euler(THREE.MathUtils.degToRad(41), 0, 0);
const targetRotation3 = new THREE.Euler(0, 0, 0);
const currentRotation3 = new THREE.Euler(0, 0, 0);

const rotationSpeed1 = 0.03;
const rotationSpeed2 = 0.05;
const rotationSpeed3 = 0.02;

const handleMouseMove = (event) => {
    const mouseX = (event.clientX / sizes.width) * 2 - 1;
    const mouseY = -(event.clientY / sizes.height) * 2 + 1;

    targetRotation1.y = mouseX * Math.PI * 0.4;
    targetRotation1.x = mouseY * Math.PI * 0.4;

    targetRotation2.y = mouseX * Math.PI * 0.4;
    targetRotation2.x = mouseY * Math.PI * 0.4;

    targetRotation3.y = mouseX * Math.PI * 0.4;
    targetRotation3.x = mouseY * Math.PI * 0.4;
};

window.addEventListener('mousemove', handleMouseMove);

const animate = function () {
    requestAnimationFrame(animate);

    currentRotation1.x += (targetRotation1.x - currentRotation1.x) * rotationSpeed1;
    currentRotation1.y += (targetRotation1.y - currentRotation1.y) * rotationSpeed1;

    example.rotation.x = currentRotation1.x;
    example.rotation.y = currentRotation1.y;

    currentRotation2.x += (targetRotation2.x - currentRotation2.x) * rotationSpeed2;
    currentRotation2.y += (targetRotation2.y - currentRotation2.y) * rotationSpeed2;

    example2.rotation.x = currentRotation2.x;
    example2.rotation.y = currentRotation2.y;

    currentRotation3.x += (targetRotation3.x - currentRotation3.x) * rotationSpeed3;
    currentRotation3.y += (targetRotation3.y - currentRotation3.y) * rotationSpeed3;

    example3.rotation.x = currentRotation3.x;
    example3.rotation.y = currentRotation3.y;

    renderer.render(scene, camera);
};

animate();
