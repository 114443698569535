var api = require("!../../node_modules/style-loader/dist/runtime/injectStylesIntoLinkTag.js");
            var content = require("!!../../node_modules/file-loader/dist/cjs.js!./main.css");

            content = content.__esModule ? content.default : content;

var options = {"injectType":"linkTag"};

options.insert = "head";

var update = api(content, options);



